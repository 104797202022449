import { CultureCard } from "./CultureCard";
import "../css/Culture.css";
import magnifyingGlass from "../../images/magnifying-glass.svg";
import users from "../../images/users.svg";
import comment from "../../images/comment.svg";
import heart from  "../../images/heart.svg";
import bullseye from "../../images/bullseye-arrow.svg";  
import magnifyingGlassRed from "../../images/magnifying-glass-red.svg";
import usersRed from "../../images/users-red.svg";
import commentRed from "../../images/comment-red.svg";
import heartRed from "../../images/heart-red.svg";
import bullseyeRed from "../../images/bullseye-arrow-red.svg";
import { useWindowDimensions } from "../../utils/helpers";

export const Culture = () => {
    const { height, width } = useWindowDimensions();

    return (
        <div style={{ height: 650, width: '100%', marginTop: width < 768 ? 80 : 150 }}>
            <div className="culture-card-container culture-container-top">
                <CultureCard 
                    icon={bullseye} 
                    title={"Clarity"} 
                    redIcon={bullseyeRed} 
                    text={"We deliver solutions with clarity and precision, ensuring every detail is meticulously addressed."} 
                />
                {width <= 1280 ? <div style={{ width: 50, height: 20 }} /> : null}
                <CultureCard 
                    icon={heart} 
                    title={"Integrity"} 
                    redIcon={heartRed} 
                    text={"We uphold the highest standards of integrity, ensuring honesty and fairness in all our dealings."} 
                />
                {width <= 1280 ? <div style={{ width: 50, height: 20 }} /> : null}
                <CultureCard 
                    icon={magnifyingGlass} 
                    title={"Transparency"} 
                    redIcon={magnifyingGlassRed} 
                    text={"We operate with transparency, keeping you informed at every step."} 
                />
            </div>
            <div className="culture-card-container culture-container-bottom">
                {width <= 1280 ? <div style={{ width: 50, height: 20 }} /> : null}
                <CultureCard 
                    icon={comment} 
                    title={"Communication"} 
                    redIcon={commentRed} 
                    text={"Clear, consistent, and open communication is at the heart of everything we do."} 
                />
                {width <= 1280 ? <div style={{ width: 50, height: 20 }} /> : null}
                <CultureCard 
                    icon={users} 
                    title={"Professionalism"} 
                    redIcon={usersRed} 
                    text={"We maintain a high level of professionalism, delivering quality work with respect and excellence."} 
                />
            </div>
        </div>
    )
}