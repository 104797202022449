import logo from '../images/allopslogo.png';
import partner_logo from '../images/awspartner.svg';
import { openCalendar, openAPNPartnerPage } from '../utils/helpers';

export function Header() {
    return (
        <>
            <img src={logo} id="logo" alt='allops-company-logo' loading='lazy' />
            <div id="head-text-container">
                <p className="font header-text">Wouldn't it be great if you had</p>
                <p className="font gradient-text">all the Ops you need</p>
                <p className="font header-text">in one place?</p>
            </div>
            <div id="subtitle-text-container">
                <p className="font light-text">At allOps Solutions, we provide comprehensive DevOps and AWS cloud services to support your business growth and increase agility, while eliminating unstable implementations and unnecessary costs.</p>
            </div>
            <div id="ops-text-container">
                <p className="font light-text">Tailored DevOps for <span id="gradient">optimal performance</span></p>
                <p className="font light-text" id="expert-text">Expert AWS management for <span id="gradient">enhanced scalability</span></p>
                <p className="font light-text" id="cdk-text">Cloud Native development for <span id="gradient">agility, scalability and cost efficiency</span></p>
            </div>
            <button className="rounded-button" onClick={openCalendar} aria-label='schedule-btn'>Schedule a free consult</button>
            <img src={partner_logo} id="partner-logo" alt='aws-apn-logo' loading='lazy' onClick={openAPNPartnerPage} />
            <div id="separator" />
        </>
    )
}