import { industry_items, industry_items_lower, industry_items_upper } from '../utils/consts';
import { useWindowDimensions } from '../utils/helpers';
import './css/Industries.css';
import { useState } from 'react';

export const Industries = () => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const { height, width } = useWindowDimensions();

    const handleMouseEnter = (index) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(-1);
      };

    return (
        <div className="industries-background">
            <p className="industries-title font">Industries</p>
            {width > 645 ?
            <div className="industry-item-wrapper">
                {industry_items.map((industry, index) => (
                    <div style={{ 
                            minHeight: 109,
                            maxHeight: 109,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            //cursor: 'pointer',
                            position: 'relative',
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave()}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={activeIndex === index ? industry.icon : industry.icon_gray} className='industry-image' alt='industry-image' loading='lazy' />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p className="industry-title font" >{industry.title}</p>
                        </div>
                    </div>
                ))}
            </div> : 
            <>
                <div className="industry-item-wrapper">
                    {industry_items_upper.map((industry, index) => (
                        <div style={{ 
                                minHeight: 109,
                                maxHeight: 109,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                position: 'relative',
                                marginTop: '5%',
                                marginLeft: index === 0 ? '0px' : '15%',
                            }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave()}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={activeIndex === index ? industry.icon : industry.icon_gray} className='industry-image' alt='industry-image' loading='lazy' />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p className="industry-title font" >{industry.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="industry-item-wrapper">
                    {industry_items_lower.map((industry, index) => (
                        <div style={{ 
                                minHeight: 109,
                                maxHeight: 109,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                position: 'relative',
                                marginTop: '0%',
                                marginLeft: index === 0 ? '0px' : '12%',
                            }}
                            onMouseEnter={() => handleMouseEnter(index+3)}
                            onMouseLeave={() => handleMouseLeave()}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={activeIndex === index+3 ? industry.icon : industry.icon_gray} className='industry-image' alt='industry-image' loading='lazy' />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p className="industry-title font" >{industry.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </>}
        </div>
    )
}