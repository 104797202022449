import aigray from '../images/aipractitionergray.svg';
import ai from '../images/aipractitioner.svg';
import cloudpracgray from '../images/cloudpractitionergray.png';
import cloudprac from '../images/cloudpractitioner.png';
import sysgray from '../images/sysopsgray.svg';
import sys from '../images/sysops.svg';
import devgray from '../images/developergray.svg';
import dev from '../images/developer.svg';
import devopsgray from '../images/devopsgray.svg';
import devops from '../images/devops.svg';
import datagray from '../images/datagray.svg';
import data from '../images/data.svg';
import kubernetesgray from '../images/kubernetesgray.png';
import kubernetes from '../images/kubernetes.png';
import mlgray from '../images/mlgray.svg';
import ml from '../images/ml.svg';
import saasocgray from '../images/saassociategray.svg';
import saasoc from '../images/saassociate.svg';
import solarchgray from '../images/solutionsarchitectgray.svg';
import solarch from '../images/solutionsarchitect.svg';
import teragray from '../images/teraformgray.png';
import tera from '../images/teraform.png';
import securitygray from '../images/securitygray.svg';
import kubernetesadmin from '../images/kubernetesadmin.svg';
import kubernetesadmingray from '../images/kubernetesadmingray.svg';
import kubernetesapplication from '../images/kubernetesapplication.svg';    
import kubernetesapplicationgray from '../images/kubernetesapplicationgray.svg';
import awsnetworking from '../images/awsadvnetworking.svg';
import awsnetworkinggray from '../images/awsadvnetworkinggray.svg';
import kubernetessec from '../images/kubernetessec.svg';
import kubernetessecgray from '../images/kubernetessecgray.svg';
import githubsec from '../images/githubsec.svg';
import githubsecgray from '../images/githubsecgray.svg';
import vaultcert from '../images/vaultcert.svg';
import vaultcertgray from '../images/vaultcertgray.svg';
import teraformassociate from '../images/teraformassociate.svg';
import teraformassociategray from '../images/teraformassociategray.svg';
import githubactions from '../images/githubactions.svg';
import githubactionsgray from '../images/githubactionsgray.svg';
import mlspecialty from '../images/mlspecialty.svg';
import mlspecialtygray from '../images/mlspecialtygray.svg';
import awsinstructor from '../images/awsinstructor.svg';
import awsinstructorgray from '../images/awsinstructorgray.svg';
import gitops from '../images/gitops.svg';
import gitopsgray from '../images/gitopsgray.svg';
import githubadmin from '../images/githubadmin.svg';
import githubadmingray from '../images/githubadmingray.svg';
import security from '../images/security.svg';
import booksgray from '../images/booksgray.svg';
import books from '../images/books.svg';
import displaygray from '../images/displaygray.svg';
import display from '../images/display.svg';
import industrygray from '../images/industrygray.svg';
import industry from '../images/industry.svg';
import pillsgray from '../images/pillsgray.svg';
import pills from '../images/pills.svg';
import rocketygray from '../images/rocketygray.svg';
import rockety from '../images/rockety.svg';
import tractorgray from '../images/tractorgray.svg';
import tractor from '../images/tractor.svg';
import supportService from '../images/support-service.svg';
import supportServiceMini from '../images/support-mini.svg';
import supportServiceMiniRed from '../images/support-mini-red.svg';
import consultingService from '../images/consulting-service.svg';
import consultingServiceMini from '../images/consulting-mini.svg';
import consultingServiceMiniRed from '../images/consulting-mini-red.svg';
import assessmentService from '../images/assessment-service.svg';
import assessmentServiceMini from '../images/assessment-mini.svg';
import assessmentServiceMiniRed from '../images/assessment-mini-red.svg';
import performanceService from '../images/performance-service.svg';
import performanceServiceMini from '../images/performance-mini.svg';
import performanceServiceMiniRed from '../images/performance-mini-red.svg';
import testimonialPerson from '../images/person.svg';
import testimonialRami from '../images/rami.svg';
import testimonialJeff from '../images/jeff.svg';
import testimonialJamey from '../images/jamey.svg';
import testimonialDamir from '../images/damir.svg';
import envelope from '../images/envelope.svg';
import phone from '../images/phone.svg';

export const certificates_url = [
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-solutions-architect-associate",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-solutions-architect-professional",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-sysops-administrator-associate",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-devops-engineer-professional",
    "https://www.credly.com/org/the-linux-foundation/badge/cka-certified-kubernetes-administrator",
    "https://www.credly.com/org/octopus-deploy/badge/gitops-at-scale",
    "https://www.credly.com/org/github/badge/github-administration",    
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-developer-associate",
    "https://www.credly.com/org/the-linux-foundation/badge/ckad-certified-kubernetes-application-developer",
    "https://www.credly.com/org/hashicorp/badge/hashicorp-certified-terraform-associate-003",
    "https://www.credly.com/org/github/badge/github-actions",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-advanced-networking-specialty",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-security-specialty",
    "https://www.credly.com/org/hashicorp/badge/hashicorp-certified-vault-operations-professional",
    "https://www.credly.com/org/the-linux-foundation/badge/cks-certified-kubernetes-security-specialist",
    "https://www.credly.com/org/github/badge/github-advanced-security",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-ai-practitioner",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-data-engineer-associate",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-machine-learning-engineer-associate",
    "https://www.credly.com/org/amazon-web-services/badge/aws-certified-machine-learning-specialty",
    "https://www.credly.com/org/amazon-web-services/badge/authorized-instructor",
]

export const certificates_title = [
    "aws-solutions-architect-associate",
    "aws-solutions-architect-professional",
    "aws-sysops-administrator-associate",
    "aws-devops-engineer-professional",
    "kubernetes-administrator",
    "gitops-certified",
    "github-admin",
    "aws-developer-associate",
    "kubernetes-application-developer",
    "terraform-associate",
    "github-actions",
    "aws-advanced-networking-specialty",
    "aws-security-specialty",
    "vault-certified-operations",
    "certified-kubernetes-security-specialist",
    "github-advanced-security",
    "aws-ai-practioner",
    "aws-data-engineer-associate",
    "aws-machine-learning-engineer-associate",
    "aws-machine-learning-specialty",
    "aws-authorized-instructor",
]

export const certificates_gray = [
    saasocgray,
    solarchgray,
    sysgray,
    devopsgray,
    kubernetesadmingray,
    gitopsgray,
    githubadmingray,
    devgray,
    kubernetesapplicationgray,
    teraformassociategray,
    githubactionsgray,
    awsnetworkinggray,
    securitygray,
    vaultcertgray,
    kubernetessecgray,
    githubsecgray,
    aigray,
    datagray,
    mlgray,
    mlspecialtygray,
    awsinstructorgray,
];

export const certificates = [
    saasoc,
    solarch,
    sys,
    devops,
    kubernetesadmin,
    gitops,
    githubadmin,
    dev,
    kubernetesapplication,
    teraformassociate,
    githubactions,
    awsnetworking,
    security,
    vaultcert,
    kubernetessec,
    githubsec,
    ai,
    data,
    ml,
    mlspecialty,
    awsinstructor,
];

const tab_items1 = [
    "Automation of CI/CD Processes", 
    "Infrastructure as Code (IaC)", 
    "Monitoring and Logging", 
    "Security and Compliance",
    "Cost Optimisation",
    "Microservices Architecture (Docker, Kubernetes)",
    "Serverless Architecture"
];

const tab_items2 = [
    "Develop comprehensive plans to ensure business continuity in case of unexpected events.",
    "Compliance and Business Continuity",
    "Backup and Recovery",
    "Reliability",
    "Cost Control"
];

const tab_items3 = [
    "Architecture analysis and design", 
    "Application modernization",
    "Microservices and container-based design", 
    "Serverless Architecture", 
    "CI/CD Integration",
    "Application development (Python, Typescript, and other)"
];

const tab_items4 = [
    "Migration assessment and roadmap",
    "Migration plans",
    "Risk Mitigation",
    "Cost Analysis",
    "Post-migration support",
    "On-Premises to Cloud Migration", 
    "Cross-Cloud Provider Migration"
];

export const tab_items = {
    0: tab_items1,
    1: tab_items2,
    2: tab_items3,
    3: tab_items4
};

export const industry_items = [
    {
        title: "AdTech",
        icon_gray: rocketygray,
        icon: rockety
    },
    {
        title: "Education",
        icon_gray: booksgray,
        icon: books
    },
    {
        title: "Healthcare",
        icon_gray: pillsgray,
        icon: pills
    },
    {
        title: "FinTech",
        icon_gray: displaygray,
        icon: display
    },
    {
        title: "Manufactoring",
        icon_gray: industrygray,
        icon: industry
    },
    {
        title: "Agriculture",
        icon_gray: tractorgray,
        icon: tractor
    }
]

export const industry_items_upper = [
    {
        title: "AdTech",
        icon_gray: rocketygray,
        icon: rockety
    },
    {
        title: "Education",
        icon_gray: booksgray,
        icon: books
    },
    {
        title: "Healthcare",
        icon_gray: pillsgray,
        icon: pills
    },
]

export const industry_items_lower = [
    {
        title: "FinTech",
        icon_gray: displaygray,
        icon: display
    },
    {
        title: "Manufactoring",
        icon_gray: industrygray,
        icon: industry
    },
    {
        title: "Agriculture",
        icon_gray: tractorgray,
        icon: tractor
    }
]

export const service_items = [
    {
        title: "Performance optimization",
        text: "Optimize your systems for maximum performance and reliability. Avoid hidden costs.",
        icon: performanceService,
        icon_gray: performanceServiceMini,
        icon_red: performanceServiceMiniRed
    },
    {
        title: "Consulting and training",
        text: "Receive professional advice on best practices in DevOps and cloud-native development. Equip your team with the skills and knowledge needed to succeed in the cloud.",
        icon: consultingService,
        icon_gray: consultingServiceMini,
        icon_red: consultingServiceMiniRed
    },
    {
        title: "24/7 Support",
        text: "Benefit from our continuous support services to keep your operations running smoothly.",
        icon: supportService,
        icon_gray: supportServiceMini,
        icon_red: supportServiceMiniRed
    },
    {
        title: "Continuos infrastructure assessment",
        text: "Evaluate and enhance the security of your existing infrastructure. Identify opportunities to reduce costs and improve efficiency in your cloud environment.",
        icon: assessmentService,
        icon_gray: assessmentServiceMini,
        icon_red: assessmentServiceMiniRed
    },
]

export const testimonials = [
    {
        image: testimonialJeff,
        text: `"Our team at Yahoo had the pleasure of working with allOps Solutions for over five years, and their
                            AWS SRE/DevOps support was nothing short of exceptional. The teams expertise and dedication 
                            were critical in running and optimizing our infrastructure, improving deployment processes, and
                            ensuring the reliability and security of our systems. They consistently went above and beyond to
                            meet our needs, providing solutions that helped us scale and maintain efficiency. The 
                            professionalism and responsiveness of their team made them an invaluable partner. I highly 
                            recommend allOps Solutions to organizations seeking top-tier AWS SRE/DevOps support."`,
        name: "Jeff Gahan",
        title: "Director of Engineering @ Yahoo"
    },
    {
        image: testimonialJamey,
        text: `"allOps was an invaluable during our transition to AWS. We set an aggressive timeline and we probably wouldn't have hit that without their dedicated work. Their practical knowledge was crucial to our successful project, so if you're looking for expertise, you are unlikely to do any better."`,
        name: "Jamey Heit",
        title: "Co-founder and CEO @ Ecree"
    },
    {
        image: testimonialRami,
        text: `"We have had the pleasure of partnering with allOps for the past 8 years. Together, we have successfully delivered projects across three different continents - North America, Europe and Asia/Middle East. allOps has proven to be our go-to partner for all DevOps engineering needs."`,
        name: "Rami Nabulsi",
        title: "Senior IT Consultant/Partner @ TN-TECH"
    },
    {
        image: testimonialDamir,
        text: `"Working with allOps has been a fantastic experience. Their dedication to educating the community in DevOps and AWS is truly commendable. Dzenana, Dzenan and their team consistently deliver high-quality work, going above and beyond expectations to empower their clients. Their professionalism and collaborative spirit make them a great team to work with."`,
        name: "Damir Topic",
        title: "Managing Director @ evoila Bosnia"
    },
]

export const footer_bottom_content = [
    {
        icon: envelope,
        title: null,
        content: 'info@allops.co'
    },
    {
        icon: phone,
        title: null,
        content: '+387 61 792 636'
    },
    {
        icon: null,
        title: 'ID:',
        content: '4227993630003'
    },
    {
        icon: null,
        title: 'VAT:',
        content: '227993630003'
    },
    {
        icon: null,
        title: null,
        content: 'allOps. All rights reserved. 2024.'
    },
]


