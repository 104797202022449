import React, { useState } from "react";

export const CultureCard = ({title, icon, text, redIcon}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    
    return (
        <div 
            className="card"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <>
                <div className={`${isHovered ? 'fade-out' : 'fade-in'} card`} style={{ display: isHovered ? 'none' : 'flex' }}>
                    <div style={{ width: 48 }}>
                        <img src={icon} className={`image ${isHovered ? 'fade-out' : 'fade-in'}`} alt="Card-Normal" style={{ width: isHovered ? 0 : 48 }} loading="lazy" />
                    </div>
                    <p className={`culture-card-title font ${isHovered ? 'fade-out' : 'fade-in'}`}>{title}</p>
                </div>
                <div className={`${isHovered ? 'fade-in' : 'fade-out'} card`} style={{ display: isHovered ? 'flex' : 'none' }}>
                    <div style={{ width: 32 }}>
                        <img src={redIcon} className={`image ${isHovered ? 'fade-in' : 'fade-out'}`} alt="Card-Hovered" style={{ width: isHovered ? 32 : 0 }} loading="lazy" />
                    </div>
                    <p className={`culture-card-title font ${isHovered ? 'fade-in' : 'fade-out'}`}>{title}</p>
                    <p className={`culture-card-text ${isHovered ? 'fade-in' : 'fade-out'}`}>{text}</p>
                </div>
            </>
        </div>
    )
}