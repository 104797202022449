import logo from '../images/footerlogo.svg';
import { openBlog, openGithub, openHome, openLinkedin, openPrivacyPolicy, openTOS, useWindowDimensions } from '../utils/helpers';
import github from '../images/github.svg';
import linkedin from '../images/linkedin.svg';
import { footer_bottom_content } from '../utils/consts';

export const FooterContentMobile = ({ noMarginTop, noMarginBottom }) => {
    const { height, width } = useWindowDimensions();

    return (
        <div style={{ 
            height: '100%',
            width: width > 1900 ? '80%' : '95%',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: width > 1900 ? '8%' : 0,
            marginTop: noMarginTop ? 0 : 150,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 50,
         }}>
            <div className="footer-content" style={{ marginTop: noMarginTop ? 0 : 150 }}>
                <img src={logo} className="footer-bottom-logo" alt='company-logo' loading='lazy' onClick={openHome} />
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    width: '100%' 
                }}>
                    <p className="font footer-text p-margin" onClick={openHome}>Home</p>
                    <p className="font footer-text p-margin" onClick={openBlog}>Blog</p>
                    <p className="font footer-text p-margin" onClick={openTOS}>Terms of Service</p>
                    <p className="font footer-text p-margin" onClick={openPrivacyPolicy}>Privacy Policy</p>
                </div>
                <div className="footer-address">
                    <p className="font footer-text-right u-margin" style={{ cursor: 'default', marginTop: 20 }}>allOps Solutions</p>
                    <p className="font footer-text-right p-margin" style={{ cursor: 'default', marginTop: -10 }}>Podgorani bb 88208 Potoci - Mostar</p>
                    <p className="font footer-text-right p-margin" style={{ cursor: 'default', marginTop: -10 }}>Bosnia and Herzegovina</p>
                </div>
            </div>
            <div style={{
                width: '120%',
                height: 1,
                backgroundColor: '#6A706F',
                marginTop: 20,
                marginBottom: 20,
            }} />
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: width < 1025 ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: noMarginBottom ? 0 : 70,
            }}>
                <div style={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 20,
                    width: '90%',
                }}>
                    <img src={github} alt='github-logo' onClick={openGithub} loading='lazy' style={{ cursor: 'pointer' }} />
                    <img 
                        src={linkedin} 
                        alt='linkedin-logo' 
                        loading='lazy'
                        style={{ 
                            marginLeft: width < 1025 ? 10 : 12, 
                            marginRight: width < 1025 ? 0 : 14,
                            marginTop: width < 1025 ? 0 : 0,
                            cursor: 'pointer'
                        }} 
                        onClick={openLinkedin} 
                    />
                    <div style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                        }}>
                            <div style={{ width: 1, height: 12, backgroundColor: '#6A706F', marginRight: 14, marginLeft: 14 }} />
                            {footer_bottom_content[0].icon ? <img src={footer_bottom_content[0].icon} alt={footer_bottom_content[0].content} loading='lazy' /> : null}
                            <p className="font footer-text-right" style={{ marginLeft: 5, cursor: 'default' }}>{footer_bottom_content[0].content}</p>
                            <div style={{ width: 1, height: 12, backgroundColor: 'transparent', marginRight: 0 }} />
                    </div>
                    <div style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                        }}>
                            <div style={{ width: 1, height: 12, backgroundColor: '#6A706F', marginRight: 14, marginLeft: 14 }} />
                            {footer_bottom_content[1].icon ? <img src={footer_bottom_content[1].icon} alt={footer_bottom_content[1].content} loading='lazy' /> : null}
                            <p className="font footer-text-right" style={{ marginLeft: 5, cursor: 'default' }}>{footer_bottom_content[1].content}</p>
                            <div style={{ width: 1, height: 12, backgroundColor: 'transparent', marginRight: 0 }} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 20,
                    width: '90%',
                }}>
                    <div style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                        }}>
                            {footer_bottom_content[2].title ? <p className="font footer-text">{footer_bottom_content[2].title}</p> : null}
                            <p className="font footer-text-right" style={{ marginLeft: 5, cursor: 'default' }}>{footer_bottom_content[2].content}</p>
                            <div style={{ width: 1, height: 12, backgroundColor: 'transparent', marginRight: 0 }} />
                    </div>
                    <div style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                        }}>
                            <div style={{ width: 1, height: 12, backgroundColor: '#6A706F', marginRight: 14, marginLeft: 14 }} />
                            {footer_bottom_content[3].title ? <p className="font footer-text">{footer_bottom_content[3].title}</p> : null}
                            <p className="font footer-text-right" style={{ marginLeft: 5, cursor: 'default' }}>{footer_bottom_content[3].content}</p>
                            <div style={{ width: 1, height: 12, backgroundColor: 'transparent', marginRight: 0 }} />
                    </div>
                </div>
                <div style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            marginTop: 10,
                        }}>
                            <p className="font footer-text-right" style={{ marginLeft: 5, cursor: 'default' }}>{footer_bottom_content[4].content}</p>
                </div>
            </div>
        </div>
    )
}