import "../css/TabMenu.css";
import { useState } from "react";
import { Tab } from "./Tab";
import rocket from '../../images/rocket.svg';
import rocketactive from '../../images/rocketactive.svg';
import code from '../../images/code.svg';
import codeactive from '../../images/codeactive.svg';
import cloud from '../../images/cloud.svg';
import cloudactive from '../../images/cloudactive.svg';
import siren from '../../images/siren.svg';
import sirenactive from '../../images/sirenactive.svg';
import { TabContent } from "./TabContent";
import { tab_items } from "../../utils/consts";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

export const TabMenu = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <div className="tabs-wrapper">
                <div className="vertical-tab-container">
                    <div className="tabs">
                        <div className={`tab ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
                            <Tab 
                                active={activeTab === 0}
                                title={"DevOps implementation and cloud infrastructure management"} 
                                img_active={cloudactive}
                                img={cloud}
                            />
                        </div>
                        <div className={`tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                            <Tab 
                                active={activeTab === 1}
                                title={"Disaster Recovery"} 
                                img_active={sirenactive}
                                img={siren}
                            />
                        </div>
                        <div className={`tab ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                            <Tab 
                                active={activeTab === 2}
                                title={"Cloud-Native Development"} 
                                img_active={codeactive}
                                img={code}
                            />
                        </div>
                        <div className={`tab ${activeTab === 3 ? 'active' : ''}`} onClick={() => handleTabClick(3)}>
                            <Tab 
                                active={activeTab === 3}
                                title={"Cloud Migration"} 
                                img_active={rocketactive}
                                img={rocket}
                                size_diff
                            />
                        </div>
                    </div>
                    <div className="slider-container"/>
                    <div className="vertical-slider" style={{ transform: `translateY(${activeTab * 117}%)` }}></div>
                </div>
                <TransitionGroup 
                    className="transition-group-container"
                >
                    <CSSTransition
                        key={activeTab}
                        timeout={400}
                        classNames="opac"
                    >
                        <TabContent items={tab_items[activeTab]}/>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </>
    );
}