import "./css/Testimonials.css";
import { useEffect, useRef, useState } from "react";
import React from "react";
import person from "../images/person.svg";
import chevronLeft from "../images/chevron-left.svg";
import chevronRight from "../images/chevron-right.svg";
import { testimonials } from "../utils/consts";
import { useOnScreen, useWindowDimensions } from "../utils/helpers";

export const Testimonials = () => {
    const divRef = useRef();
    const [activeIndex, setActiveIndex] = useState(0)
    const { height, width } = useWindowDimensions();
    const isVisible = useOnScreen(divRef, '0px');

    useEffect(() => {
        let interval = null;
        if(isVisible) {
            interval = setInterval(() => {
                switchTestimonialIndex(1);
            }, 15000);   
        }
        return () => clearInterval(interval);
    })

    function getImage(position) {
        var indexToReturn = 0;
        if (position === 0) return testimonials[activeIndex].image;
        if(Math.sign(position) === 1) {
            if(activeIndex + position < testimonials.length) {
                indexToReturn = activeIndex + position;
            }
            else {
                indexToReturn = activeIndex - testimonials.length + position;
            }
        }
        else {
            if(activeIndex - Math.abs(position) < 0) {
                indexToReturn = testimonials.length - Math.abs(activeIndex - Math.abs(position));
            }
            else {
                indexToReturn = activeIndex - Math.abs(position);
            }
        }
        return testimonials[indexToReturn].image;
    }

    function switchTestimonialIndex(direction) {
        if(((activeIndex < testimonials.length - 1) && Math.sign(direction) === 1) || ((activeIndex > 0) && Math.sign(direction) === -1)) {
            setActiveIndex(activeIndex + direction);
        } else {
            Math.sign(direction) === 1 ? setActiveIndex(0) : setActiveIndex(testimonials.length - 1);
        }
    }

    return (
        <>
            <div className={"testimonials-container"} ref={divRef}>
                <div className="testimonial-carousel-container">
                    {/* {width > 768 ?
                        <div className="testimonial-image-container-light">
                            <img src={getImage(-2)} alt="person" className="testimonial-image-light" loading="lazy" />
                        </div> : null
                    } */}
                    { width > 518 ?
                    <div className="testimonial-image-container-gray" onClick={() => switchTestimonialIndex(-1)}>
                        <img src={getImage(-1)} alt="person" className="testimonial-image-gray" loading="lazy" />
                    </div> : null}
                    <div className="testimonial-image-container">
                        <img src={getImage(0)} alt="person" className="testimonial-image" loading="lazy" />
                    </div>
                    { width > 518 ?
                    <div className="testimonial-image-container-gray" onClick={() => switchTestimonialIndex(1)}>
                        <img src={getImage(1)} alt="person" className="testimonial-image-gray" loading="lazy" />
                    </div> : null}
                    {/* {width > 768 ?
                        <div className="testimonial-image-container-light">
                            <img src={getImage(2)} alt="person" className="testimonial-image-light" loading="lazy" />
                        </div> : null
                    } */}
                </div>
                <div className="testimonial-head">
                    {width < 768 ? <img src={chevronLeft} style={{ marginRight: width < 429 ? 30 : width < 519 ? 50 : 70, cursor: 'pointer' }} onClick={() => switchTestimonialIndex(-1)} alt="chevron-left" loading="lazy" /> : null}
                    <div style={{ width: width < 500 ? '60%' : "100%" }}>
                        <p className="testimonial-name">{testimonials[activeIndex] && testimonials[activeIndex].name ? testimonials[activeIndex].name : ""}</p>
                        <p className="testimonial-title">{testimonials[activeIndex] && testimonials[activeIndex].title ? testimonials[activeIndex].title : ""}</p>
                    </div>
                    {width < 768 ? <img src={chevronRight} style={{ marginLeft: width < 429 ? 30 : width < 519 ? 50 : 70, cursor: 'pointer' }} onClick={() => switchTestimonialIndex(1)} alt="chevron-right" loading="lazy" /> : null}
                </div>
                <div className="testimonial-wrapper">
                    {width < 768 ? null : <img src={chevronLeft} style={{ marginRight: 30, cursor: 'pointer' }} onClick={() => switchTestimonialIndex(-1)} alt="chevron-left" loading="lazy" />}
                    <div className="testimonial-container">
                        <p className="testimonial">
                           {testimonials[activeIndex] && testimonials[activeIndex].text ? testimonials[activeIndex].text : ""}
                        </p>
                    </div>
                    {width < 768 ? null : <img src={chevronRight} style={{ marginLeft: 30, cursor: 'pointer' }} onClick={() => switchTestimonialIndex(1)} alt="chevron-right" loading="lazy" />}
                </div>
            </div>
            <div className={"testimonials-divider"} />
        </>
    )
}