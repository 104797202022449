import logo from '../images/footerlogo.svg';
import { openBlog, openGithub, openHome, openLinkedin, openPrivacyPolicy, openTOS, useWindowDimensions } from '../utils/helpers';
import github from '../images/github.svg';
import linkedin from '../images/linkedin.svg';
import { footer_bottom_content } from '../utils/consts';

export const FooterContent = ({ noMarginTop, noMarginBottom }) => {
    const { height, width } = useWindowDimensions();

    return (
        <div style={{ 
            height: '100%',
            width: width > 1900 ? '80%' : '95%',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: width > 1900 ? '8%' : 0,
            marginTop: noMarginTop ? 0 : 150,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 50,
         }}>
            <div className="footer-content" style={{ marginTop: noMarginTop ? 0 : 150 }}>
                <img src={logo} className="footer-bottom-logo" alt='company-logo' loading='lazy' onClick={openHome} />
                <div>
                    <p className="font footer-text p-margin" onClick={openHome}>Home</p>
                    <p className="font footer-text p-margin" onClick={openBlog}>Blog</p>
                </div>
                <div>
                    <p className="font footer-text p-margin" onClick={openTOS}>Terms of Service</p>
                    <p className="font footer-text p-margin" onClick={openPrivacyPolicy}>Privacy Policy</p>
                </div>
                <div className="footer-address">
                    <p className="font footer-text-right u-margin" style={{ cursor: 'default' }}>allOps Solutions</p>
                    <p className="font footer-text-right p-margin" style={{ cursor: 'default' }}>Podgorani bb 88208 Potoci - Mostar</p>
                    <p className="font footer-text-right p-margin" style={{ cursor: 'default' }}>Bosnia and Herzegovina</p>
                </div>
            </div>
            <div style={{
                width: '70%',
                height: 1,
                backgroundColor: '#6A706F',
                marginTop: 20,
                marginBottom: 20,
            }} />
            <div style={{
                width: '70%',
                display: 'flex',
                flexDirection: width < 1025 ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: noMarginBottom ? 0 : 70,
            }}>
                <img src={github} alt='github-logo' onClick={openGithub} loading='lazy' style={{ cursor: 'pointer' }} />
                <img 
                    src={linkedin} 
                    alt='linkedin-logo' 
                    loading='lazy'
                    style={{ 
                        marginLeft: width < 1025 ? 0 : 12, 
                        marginRight: width < 1025 ? 0 : 14,
                        marginTop: width < 1025 ? 20 : 0,
                        cursor: 'pointer'
                    }} 
                    onClick={openLinkedin} 
                />
                {footer_bottom_content.map((item, index) => {
                    return (
                        <div style={{ 
                            display: 'flex', 
                            flexDirection: width < 1025 ? 'column' : 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            marginTop: index === 0 && width < 1025 ? 20 : 0,
                        }} key={index}>
                            {width < 1025 ? null : <div style={{ width: 1, height: 12, backgroundColor: '#6A706F', marginRight: 28, marginLeft: 14 }} />}
                            {item.icon ? <img src={item.icon} alt={item.content} loading='lazy' /> : null}
                            {item.title ? <p className="font footer-text">{item.title}</p> : null}
                            <p className="font footer-text-right" style={{ marginLeft: 5, cursor: 'default' }}>{item.content}</p>
                            <div style={{ width: 1, height: 12, backgroundColor: 'transparent', marginRight: 14 }} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}