import logo from '../../images/allopslogo.png';

export function TOSPHeader({ title, subtitle }) {
    return (
        <>
            <img src={logo} id="logo" alt='allops-company-logo' loading="lazy" />
            <div id="head-text-container">
                <p className="font header-text">{title}</p>
            </div>
            <div id="subtitle-text-container">
                <p className="font light-text">{subtitle}</p>
            </div>
        </>
    )
}