import check from "../../images/circlecheck.svg";

export const TabContent = ({items}) => {
    return (
        <div className="tab-content">
            {items.map(title => {
                return (
                    <div 
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            marginBottom: -10, 
                            position: 'relative' 
                        }}
                        >
                        <img src={check} className="check-image" alt="check-mark" loading="lazy" />
                        <p 
                            className="font tab-font-size" 
                            style={{ 
                                color: "#5A605F", 
                                fontWeight: "100", 
                                marginLeft: 27 
                            }}
                            >
                                {title}
                            </p>
                    </div>
                )
            })}
        </div>
    )
}