import "./css/Team.css";
import teamImage from "../images/team-main-radius.svg";
import teamImageRed from "../images/team-main-radius-red.svg";
import imgDzenan from "../images/Dzenan.svg";
import imgDzenana from "../images/Dzenana.svg";
import { openAWSHeroDzenan, openAWSHeroDzenana, useWindowDimensions } from "../utils/helpers";
import { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export const Team = () => {
    const { width, height } = useWindowDimensions();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(teamImage);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            if(currentIndex === 0) {
                let image = returnImage(1);
                setCurrentIndex(1);
                setCurrentImage(image);
            }
            else {
                let image = returnImage(0);
                setCurrentIndex(0);
                setCurrentImage(image);
            }
        }, 10000);   
        return () => clearInterval(interval);
    })

    function returnImage(index) {
        if(index === 0) return teamImage;
        else return teamImageRed;
    }

    return (
        <div className="team-background">
            <div className="team-upper">
                {width < 658 ? 
                    <p className="team-title">
                        The team behind <span id="gradient">allOps</span>
                    </p>
                    :
                    null
                }
                <div className="team-image-container">
                        <div className="team-img-white-background" />
                        <TransitionGroup className="team-transition-image-div">
                            <CSSTransition
                                key={currentImage}
                                timeout={1600}
                                classNames="fade-team-img"
                            >
                                <img src={currentImage} className="team-title-image" alt="team-image" loading="lazy" />
                            </CSSTransition>
                        </TransitionGroup>
                </div>
                <div className="team-content">
                    { width > 657 ?
                        <p className="team-title">
                            The team behind <span id="gradient">allOps</span>
                        </p>
                        :
                        null
                    }
                    <p className="team-subtitle-upper">
                        Our journey began in 2011 with a passion for engineering and a commitment to excellence in DevOps and AWS cloud services. In 2017 we decided to start a company.
                    </p>
                    <p className="team-subtitle-lower">
                        With a motto that “no one can outwork us”, our team of engineers believes in fostering a work environment that encourages growth and excellence.
                    </p>
                </div>
            </div>
            <div className="team-lower">
                <div className="team-ceo">
                    <div className="team-member-image-container">
                        <img className="team-member-image" src={imgDzenan} alt="ceo-image" loading="lazy"></img>
                    </div>
                    <div className="ceo-title-container">
                        <p className="team-ceo-name">Dzenan Dzevlan</p>
                        <p className="team-ceo-title">CEO and Co-Founder</p>
                    </div>
                    <div className="team-job-title-container" onClick={openAWSHeroDzenan}>
                        <p className="team-job-title">
                            AWS Hero
                        </p>
                    </div>
                    <p className="team-title-description">
                        A walking knowledge base
                    </p>
                </div>
                <div className="team-cf">
                    <div className="team-member-image-container">
                        <img className="team-member-image team-member-image-cf" src={imgDzenana} alt='co-founder-image' loading="lazy"></img>
                    </div>
                    <div className="cf-title-container">
                        <p className="team-ceo-name">Dzenana Dzevlan</p>
                        <p className="team-ceo-title">Technical Manager and Co-Founder</p>
                    </div>
                    <div className="team-job-title-container team-job-title-container-mob-2" onClick={openAWSHeroDzenana}>
                        <p className="team-job-title">
                            AWS Hero
                        </p>
                    </div>
                    <p className="team-title-description">
                        Passionate about getting things done
                    </p>
                </div>
            </div>
        </div>
    )
}