import "./css/AwsConfigs.css";
import React, { useRef, useState, useEffect } from "react";
import usersGear from '../images/users-gear.svg';
import manufactoring from '../images/manufactoring.svg';
import cloud from '../images/cloud-check.svg';
import { useOnScreen, useWindowDimensions } from "../utils/helpers";

export const AwsConfigs = () => {
    const divRef = useRef();
    const isVisible = useOnScreen(divRef, '-200px');
    const [animationTriggered, isAnimationTriggered] = useState(false)
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (isVisible) {
            isAnimationTriggered(true);
        }
      }, [isVisible]);    

    return (
        <div className={"configs-container"} ref={divRef}>
            <div className="configs-upper">
                <p className="configs-title font">Overwhelmed by AWS configuration options?</p>
                <div style={{ marginLeft: width > 1800 ? '15%': '10%', marginRight: width > 1800 ? '15%': '10%' }}>
                    <p className="configs-subtitle" style={{ marginTop: 0 }}>Using the cloud, especially AWS, can present various challenges in configuration because clouds usually offer so many services that it's hard to make the right choice.</p>
                    <p className="configs-subtitle" style={{ marginTop: 40 }}>We're here to stop the guesswork.</p>
                </div>
            </div>
            <div className={`configs-lower ${animationTriggered ? "configs-lower-animated" : ""}`}>
                <div className="configs-items-lower-wrapper">
                    <div className="configs-items">
                        <img src={manufactoring} style={{ width: 82 }} alt="manufactoring-configs" loading="lazy" />
                        <div className="configs-text-holder">
                            <p className="configs-text-upper">Struggling with automating software delivery process?</p>
                            <p className="configs-text-lower font">Streamline your CI/CD pipelines</p>
                        </div>
                    </div>
                    <div className="configs-items">
                        <img src={usersGear} style={{ width: 82 }} alt="users-gear-configs" loading="lazy" />
                        <div className="configs-text-holder">
                            <p className="configs-text-upper">Need a specialized DevOps team?</p>
                            <p className="configs-text-lower font">Let allOps be your DevOps-as-a-Service</p>
                        </div>
                    </div>
                    <div className="configs-items">
                        <img src={cloud} style={{ width: 82 }} alt="cloud-configs"  loading="lazy" />
                        <div className="configs-text-holder">
                            <p className="configs-text-upper">Looking to develop cloud-native applications? </p>
                            <p className="configs-text-lower font">Get cloud-optimized solutions.</p>
                        </div>
                    </div>
                </div>
                <p className={"configs-text-bottom"} style={{ marginTop: 100, width: width > 2000 ? '40%': '45%' }}>
                    Whether you're new to the cloud or looking to optimize your existing infrastructure, 
                    allOps Solutions can improve your performance and efficiency.
                </p>
            </div>
        </div>
    )
}