import { useWindowDimensions } from "../../utils/helpers";

export const TreeBranch = ({number, content, title, divider, length, vertical, left, leftMargin, invisible}) => {
    const { height, width } = useWindowDimensions();

    return (
        divider ? 
            <div 
                style={{
                    width: vertical ? (width > 768 ? 1 : 0) : (width > 768 ? length : 0),
                    height: vertical ? (width > 768 ? length : length + 50) : 1,
                    backgroundColor: invisible ? 'transparent' : "#C2DAF5",
                    zIndex: -200
                }} 
            /> :
            number ? 
                <div 
                    style={{
                        border: "1px solid #C2DAF5",
                        padding: 10,
                        maxHeight: width > 420 ? 64 : width > 400 ? 32 : 28 ,
                        minWidth: width > 768 ? 64 : width > 400 ? 32 : 28,
                        visibility: width > 768 ? "visible" : 'hidden',
                        backgroundColor: "white",
                        borderRadius: 24,
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        // marginLeft: leftMargin ? (width > 1280 ? '45.5%' : width > 1023 ? '43.5%' : width > 767 ? '46%' : '43%') : 0,
                        // marginRight: leftMargin ? 0 : (width > 1280 ? '45.5%' : width > 1023 ? '43.5%' : width > 767 ? '46%' : '43%')
                        // marginLeft: leftMargin ? `${31.8}vw` : 0,
                        // marginRight: leftMargin ? 0 : `${31.8}vw`
                        marginBottom: width > 420 && width < 430 ? 30 : 0
                    }}
                >
                    <p 
                        style={{ 
                            fontWeight: 200,
                            fontSize: 32
                        }}>
                            <span id={"gradient"}>
                                {content}
                            </span>
                        </p>
                </div> :
                <div
                    className={`branch-border ${left ? "branch-border-left" : "branch-border-right"}`}
                >
                    <p 
                        style={{
                            textAlign: left ? "right" : "left",
                            fontWeight: 400,
                            fontSize: width > 1280 ? 24 : width > 1024 ? 20 : width > 768 ? 16 : 16,
                            color: "#5A605F",
                        }}
                    >
                        {title}
                    </p>
                    <p
                        style={{
                            textAlign: left ? "right" : "left",
                            fontWeight: 200,
                            fontSize: width > 1280 ? 16 : width > 1024 ? 14 : width > 768 ? 12 : 14,
                            color: "#5A605F",
                            marginTop: -10,
                        }}
                    >
                        {content}
                    </p>
                </div>
    )
}