import blackwhitelogo from '../images/logobw.svg';
import chevronup from '../images/chevronup.svg';
import { openCalendar, openHome, useDetectScroll } from '../utils/helpers';

export const StickyHeader = () => {
    const scroll = useDetectScroll();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        scroll > 100 ? 
        <div className="sticky-header">
            <img src={blackwhitelogo} style={{ marginLeft: '12%', width: 130, cursor: "pointer" }} alt='allops-company-logo' loading='lazy' onClick={openHome} />
            <button className="chevron-button" onClick={scrollToTop} aria-label='chevron-up'>
                <img src={chevronup} style={{ marginTop: 5 }} alt='chevron-up' loading='lazy' />
            </button>
            <p 
                style={{ 
                    marginRight: '10%', 
                    color: '#FF2F48', 
                    fontSize: 16, 
                    fontWeight: 800, 
                    cursor: 'pointer' 
                }} 
                className='font'
                onClick={openCalendar}
            >
                Schedule a free consult
            </p>
        </div> : null
    )
}