import './css/ContentTree.css';
import { useEffect, useRef, useState } from 'react';
import { TreeBranch } from './TreeBranch/TreeBranch';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useOnScreen, useWindowDimensions } from '../utils/helpers';

export const ContentTree = () => {
    const divRef = useRef();
    const isVisible = useOnScreen(divRef, '-100px');
    const [animationTriggered, isAnimationTriggered] = useState(false)
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if (isVisible) {
            isAnimationTriggered(true);
        }
      }, [isVisible]);    

    return (
        <div className="tree-container" ref={divRef}>
            {animationTriggered ? 
            <>
                <p className="tree-header font">How to choose between all the Ops?</p>
                <p className="tree-subtitle font">Unless you're a CTO, picking the right service for your applications and
                infrastructure is like looking for a needle in a haystack. Here's the process we've used for hundreds of companies out there:</p>
                <div className='tree-content-container'>
                    <div className='tree-content-branch'>
                        <TransitionGroup className='tree-content-branch'>
                            <div style={{ height: 20, width: width > 768 ? '100%' : 0 }} />
                            <TreeBranch
                                divider
                                length={170}
                                invisible
                            />
                            <CSSTransition
                                appear={true}
                                timeout={200}
                                classNames="animation1"
                            >
                                <TreeBranch 
                                    number 
                                    content={"1"}
                                    leftMargin
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={450}
                                classNames="animation2"
                            >
                                <TreeBranch 
                                    divider
                                    length={100}
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={700}
                                classNames="animation3"
                            >
                                <TreeBranch 
                                    title={"Assessment"}
                                    content={"Get a detailed analysis of your existing infrastructure and applications to understand your current setup and identify areas for improvement."}
                                />
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                    <TransitionGroup>
                        <CSSTransition
                            appear={true}
                            timeout={950}
                            classNames="animation16"
                        >
                            <TreeBranch 
                                divider
                                vertical
                                length={100}
                            />
                        </CSSTransition>
                    </TransitionGroup>
                    <div className='tree-content-branch'>
                        <TransitionGroup className='tree-content-branch'>
                            <CSSTransition
                                appear={true}
                                timeout={1700}
                                classNames="animation6"
                            >
                                <TreeBranch 
                                    title={"Goals"}
                                    content={"Tell us your business objectives and challenges. This helps us to enhance the assessment and narrow down to the exact solution."}
                                    left
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={1450}
                                classNames="animation5"
                            >
                                <TreeBranch 
                                    divider
                                    length={100}
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={1200}
                                classNames="animation4"
                            >
                                <TreeBranch 
                                    number 
                                    content={"2"}
                                />
                            </CSSTransition>
                            <div style={{ height: 20, width: width > 768 ? '100%' : 0 }} />
                            <TreeBranch
                                divider
                                length={180}
                                invisible
                            />
                        </TransitionGroup>
                    </div>
                    <TransitionGroup>
                        <CSSTransition
                            appear={true}
                            timeout={1950}
                            classNames="animation17"
                        >
                            <TreeBranch 
                                divider
                                vertical
                                length={100}
                            />
                        </CSSTransition>
                    </TransitionGroup>
                    <div className='tree-content-branch'>
                        <TransitionGroup className="tree-content-branch">
                        <div style={{ height: 20, width: width > 768 ? '100%' : 0 }} />
                            <TreeBranch
                                divider
                                length={170}
                                invisible
                            />
                            <CSSTransition
                                appear={true}
                                timeout={2200}
                                classNames="animation7"
                            >
                                <TreeBranch 
                                    number 
                                    content={"3"}
                                    leftMargin
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={2450}
                                classNames="animation8"
                            >
                                <TreeBranch 
                                    divider
                                    length={100}
                                />   
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={2700}
                                classNames="animation9"
                            >
                                <TreeBranch 
                                    title={"Service recommendations"}
                                    content={"Based on our assessment and your goals, you'll get a recommendation for a customized package of services."}
                                />
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                    <TransitionGroup>
                        <CSSTransition
                            appear={true}
                            timeout={2950}
                            classNames="animation18"
                        >
                            <TreeBranch 
                                divider
                                vertical
                                length={100}
                            />
                        </CSSTransition>
                    </TransitionGroup>
                    <div className='tree-content-branch'>
                        <TransitionGroup className='tree-content-branch'>
                            <CSSTransition
                                appear={true}
                                timeout={3700}
                                classNames="animation12"
                            >
                                <TreeBranch 
                                    title={"Flexibility"}
                                    content={"Our offerings are flexible and adaptable. We can adjust our services to ensure a perfect fit. There's always a solution."}
                                    left
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={3450}
                                classNames="animation11"
                            >
                                <TreeBranch 
                                    divider
                                    length={100}
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={3200}
                                classNames="animation10"
                            >
                                <TreeBranch 
                                    number 
                                    content={"4"}
                                />
                            </CSSTransition>
                            <div style={{ height: 20, width: width > 768 ? '100%' : 0 }} />
                            <TreeBranch
                                divider
                                length={180}
                                invisible
                            />
                        </TransitionGroup>
                    </div>
                    <TransitionGroup>
                        <CSSTransition
                            appear={true}
                            timeout={3950}
                            classNames="animation19"
                        >
                            <TreeBranch 
                                divider
                                vertical
                                length={100}
                            />
                        </CSSTransition>
                    </TransitionGroup>
                    <div className='tree-content-branch'>
                        <TransitionGroup className='tree-content-branch'>
                            <div style={{ height: 20, width: width > 768 ? '100%' : 0 }} />
                            <TreeBranch
                                divider
                                length={170}
                                invisible
                            />
                            <CSSTransition
                                appear={true}
                                timeout={4200}
                                classNames="animation13"
                            >
                                <TreeBranch 
                                    number 
                                    content={"5"}
                                    leftMargin
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={4450}
                                classNames="animation14"
                            >
                                <TreeBranch 
                                    divider
                                    length={100}
                                />
                            </CSSTransition>
                            <CSSTransition
                                appear={true}
                                timeout={4700}
                                classNames="animation15"
                            >
                                <TreeBranch 
                                    title={"Implementation and support"}
                                    content={"Our team works closely with you throughout the entire process. We provide continuous support and address any issues promptly."}
                                />
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
            </> : null}
        </div>
    )
}