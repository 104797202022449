export const Tab = ({title, img_active, img, size_diff, active}) => {
    return (
        <div className="tab-content-container" style={{ transition:  "opacity 0.5s, transform 0.5s ease-out", transform: "translateZ(0)" }}>
            <div style={{ width: 41, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img 
                    alt="tab-image"
                    src={active ? img_active : img} 
                    style={{ 
                        width: size_diff ? 33 : 41,
                        transition: "opacity 0.5s, transform 0.5s ease-out",
                        transform: "translateZ(0)"
                    }}
                    loading="lazy"
                />
            </div>
            <p 
                className="font tab-font-size"
                style={{ 
                    color: active ? "#5A605F" : "#5a605f48",
                    fontWeight: active ? 'bolder' : 'lighter',
                    marginLeft: 20,
                }}
            >
                {title}
            </p>
        </div>
    )
}