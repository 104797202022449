import { certificates, certificates_gray, certificates_title, certificates_url } from "../utils/consts";
import { useEffect, useRef, useState } from "react";
import './css/CertificateSlider.css';
import { useWindowDimensions, openAWSCertificateURL } from "../utils/helpers";

export const CertificateSlider = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const { height, width } = useWindowDimensions();

    const renderCertificates = certificates_gray.map((certificate, index) => {
            return  <div
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={() => openAWSCertificateURL(certificates_url[index])}
                        style={{ 
                            position: 'relative', 
                            transition: 'transform 0.4s ease-in-out, margin 0.4s ease-in-out, opacity 0.4s',
                            transform: hoveredIndex === index ? 'scale(1.7)' : 'scale(1)',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 200,
                            cursor: 'pointer'
                        }}
                    >
                        <img
                            id="certificate-image"
                            key={index}
                            src={hoveredIndex === index ? certificates[index] : certificate}
                            alt={`${certificates_title[index]}`}
                            loading="lazy"
                            style={{ 
                                marginLeft: index === 0 ? "30px" : '30px', 
                                marginRight: index === certificates_gray.length - 1 ? '30px' : '30px',
                                transition: "opacity 0.4s ease-out, margin 0.4s ease-in-out",
                                width: 100
                            }}
                        />
                    </div>
    });

    return (
        <div style={{ position: 'relative', height: 400, width: 100 * 42 }}>
            <div id="certificate-slider-container">
                {/* {renderCertificates} */}
                <div className="carousel-primary">
                    {renderCertificates}
                </div>
                <div className="carousel-primary carousel-secondary">
                    {renderCertificates}
                </div>
            </div>
        </div>
    )
}