import './App.css';
import { FooterContent } from './components/FooterContent';
import { FooterContentMobile } from './components/FooterContentMobile';
import { StickyHeader } from './components/StickyHeader';
import { TOSPHeader } from './components/TOSPPComps/TOSPHeader';
import { useWindowDimensions } from './utils/helpers';

function TermsAndConditions() {
  const { height, width } = useWindowDimensions();

  return (
    <div style={{ 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
     }}>
        <StickyHeader />
        <div style={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            textAlign: 'center',
            background: 'radial-gradient(circle 2000px at 100% 0%, rgba(27, 104, 192, 0.25), transparent 50%), radial-gradient(circle 2500px at -30% 20%, rgb(255, 47, 71, 0.25), transparent 50%)',
         }}>
            <TOSPHeader title={"Terms and Conditions"} subtitle={"Updated at October 7th, 2024"} />
            <div 
                id="terms-content"
                style={{ 
                    textAlign: 'left',
                    marginLeft: '10%',
                    marginRight: '10%',
                }}
            >
                <h1>General Terms</h1>
                <p>By accessing allOps Solutions website, you confirm that you are in agreement with and bound by the terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire website and any email or other type of communication between you and allOps Solutions.</p>
                <p>Under no circumstances shall allOps Solutions be liable for any direct or indirect damages, including but not limited to loss of data or profit, arising out of the use, or inability to use, the materials on this site.</p>

                <h1>License</h1>
                <p>allOps Solutions grants you a revocable, non-exclusive, non-transferable, limited license to use the website strictly in accordance with the terms of this Agreement.</p>
                <p>You agree to these Terms & Conditions by using the allOps Solutions Service. Violation of these Terms may result in termination of your account or access without notice.</p>

                <h1>Definitions</h1>
                <ul>
                <li><span>Cookie</span>: small amount of data generated by a website and saved by your web browser to remember information about you.</li>
                <li><span>Company</span>: refers to allOps Solutions Ltd, Podgorani bb, 88208 Potoci - Mostar, Bosnia and Herzegovina.</li>
                <li><span>Device</span>: any internet-connected device such as a phone, tablet, or computer used to access allOps Solutions.</li>
                <li><span>Website</span>: refers to allOps Solutions's site, which can be accessed via www.allops.co.</li>
                <li><span>You</span>: the individual or entity registered with allOps Solutions to use the Services.</li>
                </ul>

                <h1>Restrictions</h1>
                <ul>
                <li>You agree not to license, sell, or commercially exploit the website or make it available to any third party.</li>
                <li>Modifying, reverse-engineering, or creating derivative works from the website is prohibited.</li>
                </ul>

                <h1>Your Suggestions</h1>
                <p>Any feedback or suggestions you provide to allOps Solutions may be used freely by the company without obligation to credit or compensate you.</p>

                <h1>Cookies</h1>
                <p>allOps Solutions uses cookies to enhance the performance of the website. Most browsers allow you to disable cookies if preferred.</p>

                <h1>Changes to Terms</h1>
                <p>allOps Solutions may modify these Terms at any time. Changes will be posted on this page, and continued use of the site implies acceptance of the updated Terms.</p>

                <h1>Intellectual Property</h1>
                <p>All content and features on this website are owned by allOps Solutions and are protected by intellectual property laws. Unauthorized use is prohibited.</p>

                <h1>Third-Party Services</h1>
                <p>The website may contain links to third-party websites or services. allOps Solutions is not responsible for these third-party sites or any issues arising from their use.</p>

                <h1>Termination</h1>
                <p>This Agreement remains effective until terminated by you or allOps Solutions. allOps Solutions may terminate your access for any breach of these Terms without prior notice.</p>

                <h1>Indemnification</h1>
                <p>You agree to indemnify and hold allOps Solutions harmless from any claims arising from your use of the website.</p>

                <h1>Disclaimer</h1>
                <p>The website is provided "as is" without warranties. allOps Solutions disclaims all warranties to the maximum extent permitted by law.</p>

                <h1>Contact Us</h1>
                <p>If you have any questions, please contact us:</p>
                <ul>
                    <li><span>Via Email</span>: info@allops.co</li>
                    <li><span>Via Phone Number</span>: +387 61 792 636</li>
                    <li><span>Via Address</span>: Podgorani bb, 88208 Potoci - Mostar, Bosnia and Herzegovina</li>
                </ul>
            </div>
        </div>
        <div style={{
            width: '120%',
            height: '100%',
            backgroundColor: '#2A2C2C',
            position: 'relative',
            overflow: 'hidden',
            marginTop: '50px',
            display: width < 768 ? 'flex' : 'flex',
            alignItems: width < 768 ? 'center' : 'center',
            justifyContent: width < 768 ? 'center' : 'center',
        }}>
            { width < 768 ? 
                <FooterContentMobile noMarginBottom noMarginTop />
                :
                <FooterContent noMarginBottom  noMarginTop />
          }
        </div>
    </div>
  );
}

export default TermsAndConditions;
