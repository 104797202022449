import { useEffect, useState } from 'react';

export function useOnScreen(ref, rootMargin = "50px") {
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        if (ref.current == null) return
        const observer = new IntersectionObserver(
            ([entry]) => setIsVisible(entry.isIntersecting),
            { rootMargin }
        )
        observer.observe(ref.current)
        return () => {
            if (ref.current == null) return
            observer.unobserve(ref.current)
        }
    }, [ref.current, rootMargin])
    return isVisible
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}
  
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
}

export function useDetectScroll() {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        function handleScroll() {
            let scroll = this.scrollY;
            setScroll(scroll);
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return scroll;
}

export function openCalendar() {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  'https://calendly.com/allops',
  }).click();
}

function getBaseUrl() {
  return window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2];
}

export function openHome() {
  const baseUrl = getBaseUrl();

  window.open(baseUrl, '_self');
}

export function openTOS() {
  const baseUrl = getBaseUrl();

  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  baseUrl + '/terms-and-conditions',
  }).click();
}

export function openGithub() {
    Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  'https://github.com/allops-solutions',
  }).click();
}

export function openLinkedin() {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  'https://www.linkedin.com/company/allops/',
  }).click();
}

export function openPrivacyPolicy() { 
  const baseUrl = getBaseUrl();

  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  baseUrl + '/privacy-policy',
  }).click();
}

export function openBlog() {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  'https://blog.allops.co/',
  }).click();
}

export function openAPNPartnerPage() {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  'https://partners.amazonaws.com/partners/0010L00001v16IFQAY/',
  }).click();
}

export function openAWSHeroDzenan() {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  'https://aws.amazon.com/developer/community/heroes/dzenan-dzevlan/',
  }).click();
}

export function openAWSHeroDzenana() {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  'https://aws.amazon.com/developer/community/heroes/dzenana-dzevlan/',
  }).click();
}

export function openAWSCertificateURL(url) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href:  url,
  }).click();
}