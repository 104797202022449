import './css/Footer.css';
import logo from '../images/footerlogo.svg';
import footkubernetesadmin from '../images/kubernetes-admin-footer.svg';
import footkubernetesapplication from '../images/kubernetes-application-footer.svg';
import footawssa from '../images/aws-solutionarch-footer.svg';
import footawssecurity from '../images/aws-security-footer.svg';
import footterraform from '../images/teraform-footer.svg';
import { openCalendar, useWindowDimensions } from '../utils/helpers';
import { FooterContent } from './FooterContent';
import { FooterContentMobile } from './FooterContentMobile';

export const Footer = () => {
    const { height, width } = useWindowDimensions();

    return (
        <>
            <div className="footer-rounded">
                <div className="logo-container">
                    <img src={logo} className="footer-top-logo" alt='allops-company-logo' loading='lazy' />
                    <div className="footer-certificates">
                        <img src={footawssa} style={{ marginRight: 20 }} alt='footer-aws-solutions-architect-professional' loading='lazy' />
                        <img src={footawssecurity} style={{ marginRight: 20 }} alt='footer-aws-security-specialty' loading='lazy' />
                        <img src={footkubernetesadmin} style={{ marginRight: 20 }} alt='footer-kubernetes-administrator' loading='lazy' />
                        <img src={footkubernetesapplication} style={{ marginRight: 20 }} alt='footer-kubernetes-application-developer' loading='lazy' />   
                        <img src={footterraform} style={{ marginRight: 20 }} alt='footer-terraform-associate' loading='lazy' />   
                    </div>
                </div>
                <div className="footer-title-wrapper">
                    <p className="font footer-title">Ready to transform your cloud infrastructure and streamline your operations with AWS?</p>
                </div>
                <div className="footer-subtitle-wrapper">
                    <p className="font footer-subtitle">Contact allOps Solutions today to discuss how we can help you achieve your goals. Our team is ready to provide the expertise and support you need for success in the cloud.</p>
                </div>
                <div className='footer-schedule-btn-wrapper'>
                    <button className="footer-schedule-btn" aria-label='schedule-btn' onClick={openCalendar}>
                        <p className="font footer-btn-text">Schedule a free consult</p>
                    </button>
                </div>
            </div>
            <div className="curved-top">
                {   width < 768 ? 
                    <FooterContentMobile noMarginBottom />
                    :
                    <FooterContent noMarginBottom />
                }
            </div>
        </>
    )
}