import "./css/Cookies.css";
import cookie_bite from "../images/cookie-bite.svg";
import xmark from "../images/xmark.svg";

export const CookiesPopup = ({ onClick }) => {
    return (
        <div className="cookies-content-container">
            <div className="cookies-close">
                <div style={{ width: '100%', height: 1 }} />
                <img src={xmark} style={{ cursor: 'pointer' }} onClick={onClick} alt="close-x" /> 
            </div>
            <div className="cookies-title-wrapper">
                <img src={cookie_bite} alt="cookie-bite" />
                <p className="cookies-title">We Use Cookies!</p>
            </div>
            <div className="cookies-text-wrapper">
                <p className="cookies-text">We use cookies to provide you with a better browsing experience and understand how you interact with our website.</p> 
                <p className="cookies-text second-text">You can customize your cookie preferences to accept only the ones you're comfortable with.</p>
            </div>
            <div className="cookies-buttons-wrapper">
                <button className="cookies-button-white cookies-button" onClick={onClick}>
                    <p className="cookie-button-text">Necessary Only</p>
                </button>
                <button className="cookies-button-black cookies-button" onClick={onClick}>
                    <p className="cookie-button-text">Accept All</p>
                </button>
            </div>
        </div>
    )
}