import "./css/Services.css";
import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { service_items } from "../utils/consts";


export const Services = () => {
    const [currentItem, setCurrentItem] = useState(0);

    const handleItemChange = (index) => {
        setCurrentItem(index);
    };

    return (
        <div className="services-container">
            <div className="services-title-wrapper">
                <div className="services-title-container">
                    <p className="services-title">
                        DevOps is not about tools.
                        It's a <span id="gradient">culture and a mindset.</span>
                    </p>
                </div>
                <div className="services-subtitle-container">
                    <p className="services-subtitle">
                        DevOps is not a set of tools. It's a way to make you more agile, efficient, and support your growth, while keeping everything as secure as possible.
                    </p>
                    <p className="services-subtitle">
                        Aside from regular legwork, you'll get a set of services and supportive services.
                    </p>
                </div>
            </div>
            <div className="services-content-wrapper">
                <div className="services-items-wrapper">
                    {/* {service_items.map((item, index) => (
                            index !== currentItem ? 
                                <div key={index} className="services-item" onClick={() => handleItemChange(index)}>
                                    <div className="services-item-upper">
                                        <img src={item.icon_gray} alt={item.title} />
                                        <p className="services-item-title services-unselected">{item.title}</p>
                                    </div>
                                </div> :
                                <div key={index} className="services-item-animated">
                                        <div className="services-item-upper">
                                            <img src={item.icon_red} alt={item.title} />
                                            <p className="services-item-title services-selected">{item.title}</p>
                                        </div>
                                        <div className="services-item-lower">
                                            <p className="services-item-subtitle">{item.text}</p>
                                        </div>
                                </div>
                    ))} */}

                    {service_items.map((item, index) => {
                        console.log('currer', currentItem, index)
                        const isActive = index === currentItem;
                        return (

                            <div key={index} className={`${isActive ? "services-item-animated" : "services-item"}`} onClick={() => handleItemChange(index)}>
                                <div className="services-item-upper">
                                    <div className="services-item-icon-wrapper">
                                        <img src={isActive ? item.icon_red : item.icon_gray} alt={item.title} loading="lazy" />
                                    </div>
                                    <p className={`services-item-title ${isActive ? 'services-selected' : 'services-unselected'}`}>{item.title}</p>
                                </div>

                                <div className={`services-item-lower ${isActive ? 'active' : ''}`}>
                                    <p className="services-item-subtitle">{item.text}</p>
                                </div>
                            </div>

                        )
                    })}
                </div>
                <div className="services-image-wrapper">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'fit-content' }}>
                        <TransitionGroup className="services-transition-image-div">
                            <CSSTransition
                                key={currentItem}
                                timeout={400}
                                classNames="fade"
                            >
                                <img src={service_items[currentItem].icon} alt={`${service_items[currentItem].title} fading`} className="fading-image" loading="lazy" />
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}