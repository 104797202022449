import { useState } from 'react';
import './App.css';
import { AwsConfigs } from './components/AwsConfigs';
import { CertificateSlider } from './components/CertificateSlider';
import { ContentTree } from './components/ContentTree';
import { CookiesPopup } from './components/CookiesPopup';
import { Culture } from './components/Culture/Culture';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Industries } from './components/Industries';
import { Services } from './components/Services';
import { StickyHeader } from './components/StickyHeader';
import { TabMenu } from './components/Tab/TabMenu';
import { Team } from './components/Team';
import { Testimonials } from './components/Testimonials';

function App() {
  const [popupClosed, setPopupClosed] = useState(false);

  function closePopup() {
    setPopupClosed(true);
  }

  return (
    <div className="App">
      <div className='App-header'>
          <StickyHeader />
          {popupClosed ? null : <CookiesPopup onClick={closePopup} />}
          <div className="gradient-background columnize">
            <Header />
          </div>
          <div className="partly-red-gradient columnize">
            <CertificateSlider />
            <AwsConfigs />
            <TabMenu />
          </div>
          <ContentTree />
          <Industries />
          <Services />
          <Team />
          <Testimonials />
          <Culture />
          <Footer />
      </div>
    </div>
  );
}

export default App;
