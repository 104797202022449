import './App.css';
import { FooterContent } from './components/FooterContent';
import { FooterContentMobile } from './components/FooterContentMobile';
import { StickyHeader } from './components/StickyHeader';
import { TOSPHeader } from './components/TOSPPComps/TOSPHeader';
import { useWindowDimensions } from './utils/helpers';

function PrivacyPolicy() {
  const { height, width } = useWindowDimensions();

  return (
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}>
      <StickyHeader />
      <div style={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          textAlign: 'center',
          background: 'radial-gradient(circle 2000px at 100% 0%, rgba(27, 104, 192, 0.25), transparent 50%), radial-gradient(circle 2500px at -30% 20%, rgb(255, 47, 71, 0.25), transparent 50%)',
       }}>
          <TOSPHeader title={"Privacy Policy"} subtitle={"Updated at October 7th, 2024"} />
          <div 
              id="terms-content"
              style={{ 
                  textAlign: 'left',
                  marginLeft: '10%',
                  marginRight: '10%',
              }}
          >
              <p>allOps Solutions (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by allOps Solutions.</p>
              <p>This Privacy Policy applies to our website and associated subdomains. By accessing or using our services, you agree to our collection, storage, use, and disclosure of your personal information as described here.</p>

              <h1>Definitions and Key Terms</h1>
              <ul>
                <li><span>Cookie</span>: Small data file stored by your browser to identify your activity and preferences.</li>
                <li><span>Personal Data</span>: Information that identifies or could identify you as an individual.</li>
                <li><span>Service</span>: Refers to our website and any related services we provide.</li>
                <li><span>You</span>: A user of our services.</li>
              </ul>

              <h1>Information Automatically Collected</h1>
              <p>We may collect certain technical information automatically when you visit our website, such as IP address, browser type, and usage details. This information helps us maintain security and improve our services.</p>

              <h1>Your Consent</h1>
              <p>By using our website, you consent to this Privacy Policy.</p>

              <h1>Links to Other Websites</h1>
              <p>This Privacy Policy applies only to our website. If you follow links to third-party websites, their privacy policies will apply.</p>

              <h1>Cookies</h1>
              <p>We use cookies to improve the functionality and performance of our website. You can disable cookies in your browser settings, but this may affect your experience on our site.</p>

              <h1>GDPR Compliance</h1>
              <p>If you are in the European Economic Area (EEA), we comply with GDPR requirements regarding personal data. You have the right to access, update, delete, or restrict your data.</p>

              <h1>California Privacy Rights</h1>
              <p>If you are a California resident, you have the right to know what personal information we collect and request its deletion, among other rights.</p>

              <h1>Contact Us</h1>
              <p>If you have any questions, please reach out:</p>
              <ul>
                <li>Email: <a href="mailto:info@allops.co">info@allops.co</a></li>
                <li>Phone: +387 61 792 636</li>
                <li>Address: Podgorani bb, 88208 Potoci - Mostar, Bosnia and Herzegovina</li>
              </ul>
          </div>
      </div>
      <div style={{
          width: '120%',
          height: '100%',
          backgroundColor: '#2A2C2C',
          position: 'relative',
          overflow: 'hidden',
          marginTop: '50px',
          display: width < 768 ? 'flex' : null,
          alignItems: width < 768 ? 'center' : null,
          justifyContent: width < 768 ? 'center' : null,
      }}>
          { width < 768 ? 
            <FooterContentMobile noMarginBottom noMarginTop />
            :
            <FooterContent noMarginBottom noMarginTop />
          }
      </div>
    </div>
  );
}

export default PrivacyPolicy;
